/* *{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.custrev{
    height: 100px;
    background-color: rgb(66, 65, 65);
    border-radius: 7px;
    display: flex;
    justify-content: flex-start;
    padding: 10px;
    margin-top: 10px;
    text-align: center;
    font-style: italic;
}

.nocustrev{
    height: 100px;
    background-color: rgb(66, 65, 65);
    border-radius: 7px;
    padding: 10px;
    margin-top: 10px;
}


.ppic{
    height: 3em;
    margin-left: -3px;
    border-radius: 70%;
}

.letsrevw{
    display: flex;
    width: 100%;
    padding: 0.3em;
}

.textrev{
    padding-left: 1em;
    display: flex;
    justify-content: space-between;
}

.btns{
    display: flex;
    flex-direction: column;
    gap: 0.7em;
    margin-left: 49em;
}

.likes{
    display: flex;
    gap: 1em;
}


.replies{
    padding: 1em;
    text-align: left;
}

.replysection{
    padding: 0.8em;
    background-color: rgb(64, 63, 63);
    border-radius: 2em;
    margin-top: 1em;
}

.handle{
    display: flex;
    gap: 1em;
}


 */

 *{
  padding: 0;
  padding-bottom: 1px;
  margin: 0;
  box-sizing: border-box;
}

 .letsrevw {
    display: flex;
    margin-bottom: 20px;
  }
  
  .ppic {
    width: 4em;
    height: 4em;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .textrev {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
  }
  
  .mainrev {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 70%;
  }

  .reviewtext{
    display: flex;
   /* background-color: rgb(78, 74, 74); */
   text-align: start;
  }

  .edit{
    margin-left: -30em;
    padding-bottom: 4px;
  }
  
  h3 {
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  p {
    font-size: 14px;
    margin-bottom: 10px;
  }

 
  .likes {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
.changer{
  height: 4vh;
  border-radius: 0.5em;
  font-size: 1rem;
  background-color: rgb(51, 49, 49);
}

  .like-btn {
    border: none;
    background-color: transparent;
    cursor: pointer;
  }
  
  .replysection {
    margin-top: 10px;
    margin-left: 3em;
  }
  
  .replyhere {
    /* width: 100%; */
    margin-bottom: 10px;
  }
  
  .btns {
    display: flex;
    gap: 1vw;
    align-items: flex-start;
    margin-left: 5vw;
    margin-top: 0vw;
  }
  
  button {
    border: none;
    background-color: transparent;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
  }
  
  button:hover {
    text-decoration: underline;
  }
  
  .replies {
    margin-top: 10px;
    margin-left: 2em;
  }
  
  .reply {
    display: flex;
    margin-bottom: 10px;
    gap: 1em;
  }
  
  .reply p {
    font-size: 14px;
    margin: 0;
  }
  
  .reply b {
    margin-right: 5px;
  }

  .userrepl{
    text-align: left;
  }
  
  .replyform{
    display: flexbox;
    width: 7em;
  }

  .rpic {
    width: 50px;
    height: 50px;
    border-radius: 50%;    
  }