*{
    padding: 0;
}

html,
body {
    height: 100%;
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.content {
    flex: 1;
}

.footer{
    background-color: rgb(62, 60, 60);
    border-top-left-radius: 1em;
    border-top-right-radius: 1em;
    padding: 2em;
    bottom: 0;
    margin-top: 4%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}

.contacts{
    text-align: left;
    margin-left: 2em;
}

.icons{
    display: flex;
    flex-direction: column;
    padding: 1em;
    gap: 1em;
}