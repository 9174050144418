.terms{
    text-align: left;
    margin-left: 7vmin;
}

.terms h1{
    color: red;
}

.terms h2{
    color: red;
}

.terms p{
    color: white;
}