.card {
  display: inline-block;
  transition: transform 0.2s;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  margin: 0.19rem;
  cursor: pointer;
  min-width: 200px;
  height: 300px;
  border: 1px solid rgb(99, 99, 99);
}

.card:hover {
  transform: scale(1.1);
  z-index: 1000;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.cards-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.btnlike{
  position: absolute;
  width: 100%;
  bottom: 0;
  align-items: center;
  z-index: 1;
}

.card-overlay {
  position: absolute;
  padding: 0 1rem 1rem 1rem;
  bottom: 0px;
  height: 290px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  background-image: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 1));
  opacity: 0;
  transition: opacity 0.2s;
}

.card-overlay:hover {
  opacity: 1;
}

.card-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
}

.card-runtime {
  font-size: 1rem;
  margin-bottom: 8px;
}

.card-rating {
  margin-left: 8px;
}

.card-desc {
  font-size: 0.8rem;
  margin-bottom: 8px;
}

.skeleton {
  height: 100%;
  width: 100%;
  background-color: #444;
  animation: loading 1s ease-in-out infinite alternate;
}

@keyframes loading {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
