.Container{
    width: 97%;
    background-color: rgb(21, 17, 17);
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 2em 0 1.5em 0 ;
    padding: 0.5em;
    border-radius: 0.5em;
  }

  .Containertop{
    width: 99%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(21, 17, 17);
    padding: 0.3em;
  }
  
  .Heading{
    font-size: clamp(20px, 4.8vw, 30px);
    color: red;
  }
  

  
  .Containerbottom{
    width: 100%;
    overflow-y: hidden;
  }
  
  .Containerbottom::-webkit-scrollbar{
    display: none;
  }
  
  .mainthing{
    display: flex;
    flex-direction: row;
    /* justify-content: space-evenly; */
    align-items: center;
    height: 42vh;
    min-height: 10vmin;
    width: 330em;
    margin-top: 0.5em;
  }
  
  .Posterimage{
    height: 35vmin;
    min-height: 18em;
    width: 28vmin;
    min-width: 14em;
    min-width: 12em;
    overflow: hidden;
    border-radius: 0.2em;
    box-shadow: 1px 1px 10px rgb(88, 88, 88);
    background-color: rgb(221, 219, 219);
    margin: 1vmin 2vmin 1vmin 2vmin;
    transform: scale(1.2);
    
  }

  .postcard{
    transition: transform .2s;
  }

  .postcard:hover {
    transform: scale(1.2);
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

