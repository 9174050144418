.partImage{
    height: 560px;
}

.partImage > img {
    margin: auto;
    display: block;
    width: 100%;
    
}

.partimage-overlay{
    position: absolute;
    padding: 5rem;
    height: 70%;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    align-items: flex-start;
     background-image: linear-gradient(rgb(0,0,0,0), rgb(0,0,0,1));
    opacity: 1;
    transition: opacity .3s; 
    bottom: -1px;
}

.container1{
    position: relative;
    margin: auto;
}

.partimage-title{
    font-weight: 900;
    font-size: 4rem;
    margin-bottom: 0.4rem;
    text-align: left
}

.partimage-run{
    font-size: 2rem;
    margin-bottom: 1rem;
 } 

.partimage-rating{
    margin-left: 3rem;
}

.partimage-desc{
    font-style: italic;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    display: flex;
    text-align: left;
    width: 50%;
} 

swiper-button-prev slider-arrow{
    color: red;
}

.allCategoriesContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media (max-width: 768px) {
    .swiper_container {
      height: 400px;
    }
    .partimage-overlay{
        display: none;
    }
  }
  
  @media (max-width: 576px) {
    .swiper_container {
      height: 300px;
    }
  }
  
  @media (max-width: 414px) {
    .swiper_container {
      height: 25em;
    }
    .partimage{
      height: 25em;
    }
  }
  
  @media (max-width: 375px) {
    .swiper_container {
      height: 220px;
    }
  }