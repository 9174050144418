.list {
    padding: 0 3rem 3rem 3rem;
}

.listTitle {
font-size: 1.75rem;
margin:  2.5rem;
}

.listCard {
    display: flex;
    flex-wrap: wrap ;
    justify-content: center;
}

.loadingpage{
    margin-top: 18%;
    margin-left: -1%;
    min-height: 80vh;
}

