.ticketsearch{
    width: 20vw;
    height: 4em;
    background-color: rgb(71, 70, 70);
    border:  solid red;
    border-radius: 1em;
    /* margin-top: 4em; */
}

.suggestion-item{
  padding: 1vh;
  width: 100vw;
}

.suggestion-item:hover{
  background-color: #6d6969;
  color: red;
  width: fit-content;
}
.suggestions-container ul {
  list-style-type: none; 
  padding-top: 1vh ; 
  padding-bottom: 1vh;
  margin: 0; 
  margin-top: 1vh;
  text-align: left;
  background-color: gray;
  border-radius: 1vw;
  position: absolute;
  width: 20%;
  z-index: 3;
}


.ticketsearcher{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.bookbtn{
    position: absolute;
    bottom: 0;
    align-items: center;
    z-index: 1;
}

.bookcard{
    display: inline-block;
    transition: transform 0.2s;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin: 0.19rem;
    cursor: pointer;
    min-width: 200px;
    height: 300px;
    border: 1px solid rgb(99, 99, 99);
}

.bookcard:hover{
    transform: scale(1.2);
    z-index: 1000;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
      rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
      rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.book-img{
    width: 100%;
  height: 100%;
  object-fit: cover;
}

.book-overlay {
    position: absolute;
    padding: 0 1rem 1rem 1rem;
    bottom: 0px;
    height: 290px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    background-image: linear-gradient(rgb(0, 0, 0, 0), rgb(0, 0, 0, 1));
    opacity: 0;
    transition: opacity 0.2s;
  }
  
  .book-overlay:hover {
    opacity: 1;
  }

  .book-title{
    font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 8px;
  color: white;
  }