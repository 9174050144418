.navbar {
  position: fixed;
  display: flex;
  /* justify-content: space-around; */
  
  margin: 0;
  width: 100%;
  z-index: 2;
  transition-timing-function: ease-in;
  transition: all 0.5s;
  height: 12vh;
}

/* .container {
  display: flex;
  justify-content: space-between;
  gap: 30vw;
  height: 84px;
} */

.left {
  /* margin-top: 1em; */
  display: flex;
  align-items: center;
  width: 85%;
  justify-content: center;
  /* gap: 2em; */
}

.right{
  margin-top: 1em;
  margin-right: 1.4em;
  align-items: center;
  width: 15%;
}

.spans {
  width: 80%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  /* margin-left: -14em;
  margin-top: -0.7em; */
}

span {
  /* margin-right: 20px; */
  cursor: pointer;
}

.dropdown-content {
  position: fixed;
  top: 10%;
  /* right: 1070px; */
  background-color: rgb(78, 76, 76);
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  left: 31% ;
  overflow: hidden;
  padding: 10px;
  animation: fade-in 0.3s ease;
}

.dropdown-content span {
  display: block;
  padding: 10px;
  color: rgb(239, 236, 236);
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.dropdown-content span:hover {
  background-color: #6d6969;
  color: red;
}

.category-span {
  position: relative;
}

.logo {
  height: 11em;
  width: 14em;
  /* margin-left: -1em; */
  cursor: pointer;
}

.navbarblack {
  background-color: black;
}

span {
  color: white;
}

* {
  margin: 0;
  padding: 0;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }

  .left {
    margin-right: auto;
  }

  .right {
    margin-right: 0;
  }

  .spans {
    display: none;
  }

  .navbar {
    flex-direction: column;
    height: auto;
  }

  .navbarblack {
    background-color: rgba(0, 0, 0, 0.8);
  }

  /* .logo {
    position: relative;
    top: unset;
    left: unset;
    margin: -2em ;
  } */

  .navbar > .container > div {
    width: 100%;
    text-align: center;
  }

  .dropdown-content {
    position: relative;
    top: unset;
    right: unset;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
    padding: 0;
  }

  .dropdown-content span {
    display: block;
    padding: 15px;
    color: white;
    text-align: center;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .dropdown-content span:first-child {
    border-top: none;
  }

  .dropdown-content span:last-child {
    border-bottom: none;
  }

  .category-span {
    display: none;
  }

  .menu-icon {
    cursor: pointer;
    margin-top: 15px;
    margin-right: 20px;
    color: white;
    font-size: 2rem;
    display: block;
    text-align: right;
  }

  .menu-icon:hover {
    color: #f9f9f9;
  }

  .menu-icon:focus {
    outline: none;
  }

  .menu-btn {
    display: none;
  }

  .nav-links {
    display: none;
  }

  .nav-links.active {
    display: block;
    position: absolute;
    width: 100%;
    top: 60px;
    left: 0;
    background: black;
    z-index: 1;
  }

  .nav-links li {
    text-align: center;
    width: 100%;
    padding: 15px;
  }

  .nav-links a {
    color: white;
    font-size: 1.2rem;
    text-transform: uppercase;
    text-decoration: none;
    letter-spacing: 2px;
    display: block;
  }
}