.chosen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
}

.introduction {
  width: 100%;
}

.bg {
  height: 80vh;
  width: 100%;
}

.thumbbox {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.thumbnail img {
  height: 50vh;
  z-index: 1;
  position: relative;
  bottom: 10vh;
}

.text-styling {
  color: white;
}

.poster-name-rating {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

.date {
  text-align: left;
  padding-left: 10px;
  font-size: x-small;
  font-style: italic;
  color: red;
}

.description {
    text-align: left;
    padding-left: 10px;
    color: white;
    margin-top: 5%;
    width: 50vw;
}

.description h1 {
    color: red;
}

.uselinks {
  position: relative;
  bottom: -12em;
  display: flex;
  justify-content: space-between;
  width: 80%;
}

.uselinks h1{
  color: rgb(255, 9, 9);
  margin-left: -7em;
  margin-top: -0.4em;
}

.homebutton{
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  padding: 40% 80%;
  width: 4em;
  border-radius: 14%;
  border: 2px solid white;
  border-style: dashed;
}

.imdbbutton{
  color: rgb(255, 255, 255);
  background-color: rgb(0, 0, 0);
  padding: 40% 80%;
  width: 4em;
  border-radius: 14%;
  border: 2px solid white;
  border-style: dashed;
}

.prodpart{
  margin-top: 14em;
  color: red;
}

.productionheading{
  margin-bottom: 1em;
}

.companyimage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ytrevs{
  width: 100%;
  margin-top: 14em;
}

.productions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 17em;
  background-color: white;
}

.companyname {
  width: 200px;
  margin: 2rem;
}

.review{
  width: 70vw;
  height: 7vh;
  border-radius: 0.5em;
  font-size: 1rem;
  background-color: rgb(51, 49, 49);
}

.review-container{
  display: flex;
  justify-content: space-between;
  gap: 2vw;
  padding: 2vh 0vh;
  padding-bottom: 2vh;
}

.userrevs{
  background-color: rgb(74, 72, 72);
  
  border-radius: 2em;
  margin-left: 2vw;
}

.review::placeholder{
  color: #fff;
}

.review-section h2{
  color: red;
  text-align: left;
  margin-left: -4.7em;

}
@media screen and(max-width:662px) {
  .chosen{
    display: flex;
    flex-direction: column;
  }

  .introduction{
    width: 100%;
  }

  .ytrevs{
    display: none;
  }
  .thumbbox{
    display: grid;
    grid-template-columns: auto;
  }
}