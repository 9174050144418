.chosen {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  min-width: 100%;
}

.introduction {
  width: 100%;
}

.bg {
  height: 80vh;
  width: 100%;
}

.thumbbox {
  display: grid;
  grid-template-columns: 1fr 5fr;
}

.thumbnail img {
  height: 50vh;
  z-index: 1;
  position: relative;
  bottom: 10vh;
}

.text-styling {
  color: white;
}

.poster-name-rating {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
}

.date {
  text-align: left;
  padding-left: 10px;
  font-size: x-small;
  font-style: italic;
  color: red;
}

.description {
  text-align: left;
  padding-left: 10px;
  color: white;
  margin-top: 5%;
  width: 50vw;
}



.description h1 {
  color: red;
}

.review {
  width: 80vw;
  height: 7vh;
  border-radius: 0.5em;
  font-size: 1rem;
  /* background-color: rgb(51, 49, 49); */
}

.review-container {
  display: flex;
  gap: 2vw;
  padding: 2vh 0vh;
  padding-bottom: 2vh;
}

.userrevs {
  background-color: rgb(74, 72, 72);
  padding: 1em;
  border-radius: 2em;
  width: 90vw;
  margin-left:0vw;
}

.review::placeholder {
  color: #fff;
}


.review-section h2 {
  color: red;
  text-align: left;
  margin-left: 1.7em;
}
@media screen and(max-width:662px) {
  .chosen {
    display: flex;
    flex-direction: column;
  }

  .introduction {
    width: 100%;
  }

  .ytrevs {
    display: none;
  }
  .thumbbox {
    display: grid;
    grid-template-columns: auto;
  }
}
